import React, { useCallback, useContext, useEffect, useState } from "react";
import { IoCameraReverse } from "react-icons/io5";
import { IoMdFlash } from "react-icons/io";
//import QrReader from "react-qr-reader";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import AppContext, { AppContextType } from "../contexts/AppContext";
interface ScannerQrCodeProps {
	numberComanda?: number;
	onAction(): void;
	onClose(): void;
	header?: any
}
const ScannerQrCode: React.FC<ScannerQrCodeProps> = ({
	numberComanda,
	onAction,
	onClose,
	header
}) => {
	const { info, verifyFicha } = useContext<AppContextType>(AppContext);
	const [reverseCamera, setReverseCamera] = useState<boolean>(false);
	const [flashLight, setFlashLight] = useState(false);

	const handleScan = async (scanData) => {
		let newData = scanData?.toString();
		let newString = newData.split("/").pop();

		if (newString === numberComanda && info.utiliza_comanda_codigo_barras === 0) { //&& newData.includes(tipoFicha.toLowerCase())) { // Verifica se o codigo escaneado possui a ficha atual
			onAction();
			onClose();
		} else if (!newData.includes("/") && info.utiliza_comanda_codigo_barras === 1) {
			let newNumber = parseInt(newData, 10);
			const barcode = { type: "Comanda", number: newNumber.toString(), mesa_vinculada: Number(numberComanda) } //Cria session storage de ficha
			const fichaExiste = await verifyFicha(header, newNumber, 'COMANDA');
			if (!fichaExiste) {
				alert(
					`A mesa ou comanda de número: ${newNumber} não existe.`
				);
				onClose();
				return;
			}
			sessionStorage.setItem("@CHEF:barcode", JSON.stringify(barcode));
			onClose();
			onAction();
		} else {
			return
		}
	};
	/*const handleError = (err) => {
		console.error(err);
	};*/

	const checkPermission = useCallback(async () => {
		navigator.permissions
			//@ts-ignore
			.query({ name: "camera" })
			.then((permissionObj) => {
				if (permissionObj.state === "granted") {
					//permission has already been granted, no prompt is shown
				} else if (permissionObj.state === "prompt") {
					alert(
						"Para finalizar o seu pedido, você terá que aceitar a permissão para utilizar sua câmera"
					);
					//there's no peristent permission registered, will be showing the prompt
				} else if (permissionObj.state === "denied") {
					alert(
						"Você negou a permissão para utilizar sua câmera, vá no canto esquerdo da barra de navegação, clique no cadeado ou câmera e redefina sua permissão"
					);
					onClose();
					//permission has been denied
				}
				// ... check the permission object ...
			})
			.catch((error) => {
				// couldn't query the permission
				console.error(error);
			});
	}, [onClose]);

	useEffect(() => {
		checkPermission();

		const time = setTimeout(() => {
			checkPermission();
		}, 3000);

		return () => {
			clearTimeout(time);
		};
	}, [checkPermission]);

	return (
		<div className="Alert">
			<div className="box-scanner">
				<div>
					<h4>
						APONTE PARA O CODIGO
						<button style={{
							background: "transparent",
							color: "black",
							border: "none",
							position: "absolute",
						}} onClick={() => setFlashLight(!flashLight)}>
							<IoMdFlash size={25} />
						</button>
					</h4>
				</div>
				<div className="box-scanner-video">
					{/*<QrReader
							facingMode={reverseCamera ? "user" : "environment"}
							delay={500}
							onError={handleError}
							onScan={handleScan}
							style={{ width: "100%", heigth: "150px" }}
					/>*/}
					<BarcodeScannerComponent
						facingMode={reverseCamera ? "user" : "environment"}
						torch={flashLight}
						width={"100%"}
						height={"100%"}
						onUpdate={(err, result) => {
							if (result) {
								handleScan(result);
							};
						}}
					/>

				</div>
				<div className="box-scanner-footer">
					<button className="box-scanner-button" onClick={onClose}>
						FECHAR
					</button>
					<button
						className="box-scanner-button"
						onClick={() => setReverseCamera(!reverseCamera)}
					>
						<IoCameraReverse size={30} color="#58585a" />
						<span>INVERTER CAMERA</span>
					</button>
				</div>
			</div>
		</div>
	);
};

export default ScannerQrCode;
